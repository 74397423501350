import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ContentBox from "..\\..\\..\\..\\src\\components\\lessons\\contentBox";
import ExpandedContent from "..\\..\\..\\..\\src\\components\\lessons\\expandedContent";
import PlayButton from "..\\..\\..\\..\\src\\components\\lessons\\playbutton";
import Player from "..\\..\\..\\..\\src\\components\\lessons\\player";
import Ruby from "..\\..\\..\\..\\src\\components\\lessons\\ruby";
import * as React from 'react';
export default {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ContentBox,
  ExpandedContent,
  PlayButton,
  Player,
  Ruby,
  React
};