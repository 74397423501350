import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import Duration from './playerDuration';

import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Slider from '@material-ui/core/Slider';

const styles = theme => ({
  player: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "0 auto"
  },
  sliderRoot: {
    color: "rgba(117, 117, 117, 1)"
  },
  sliderThumb: {
    '&:focus': {
      boxShadow: "none"
    },
    '&:hover, &$active': {
      boxShadow: "0px 0px 0px 8px rgba(188, 188, 188, 0.16)",
    }
  }
});

class Player extends Component {
  state = {
    url: null,
    playing: false,
    volume: 0.8,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  handlePlayPause = () => {
    if (!this.state.url) {
    this.setState({ url: this.props.url })
    }
    this.setState({ playing: !this.state.playing })
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = (e, valeue) => {
    this.setState({ played: parseFloat(valeue) })
  }

  handleSeekMouseUp = (e, value) => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(value))
  }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }

  render(){
    const { classes } = this.props;
    const { url, playing, played, duration} = this.state

    return (
      <div className={classes.player}>
          <ReactPlayer
            ref={this.ref}
            className='player'
            width='0'
            height='0'
            url={url}
            playing={playing}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            onEnded={this.handleEnded}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
            config={{
              file: { 
                attributes: { 
                  preload: 'none'
                } 
              } 
            }}
          />

          <div style={{width: "50px", marginLeft: "5px"}}>
            <IconButton aria-label="play or pause" onClick={this.handlePlayPause}>
              {playing ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
            </IconButton>
          </div>
          <div style={{flexGrow: 1, paddingLeft: "10px", paddingRight: "12px", paddingTop: "6px"}}>
            <Slider
              value={played}
              min={0}
              max={1}
              step={0.000001}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onChangeCommitted={this.handleSeekMouseUp} 
              aria-labelledby="audio slider"
              classes={{root: classes.sliderRoot, thumb: classes.sliderThumb}}
            />
          </div>
          <div style={{width: "80px", marginLeft: "10px"}}>
            <Duration seconds={duration * played} /> / <Duration seconds={duration} />
          </div>
          <div style={{width: "50px", marginRight: "5px"}}>
            <IconButton 
              aria-label="download"
              component={Link}
              href={url}
              style={{color: "rgba(0, 0, 0, 0.54)"}}
            >
              <GetAppIcon fontSize="small" />
            </IconButton>
          </div>
      </div>
    )
  }
}

export default withStyles(styles)(Player);