import React from 'react';

const Ruby = ({c, r}) => {
    const chaArray = c.split('');
    const rocanArray = r.split(' ');

    const ruby = chaArray.map((cha, index) => {
        return (
            <ruby>{cha}<rt>{rocanArray[index]}</rt></ruby>
        )
    })

    return (
        <span>
            {ruby}
        </span>
    )
}

export default Ruby;