import React from 'react';

const ContentBox = (props) => {
    return (
        <div style={{
            marginLeft: "-20px", 
            marginRight: "-20px", 
            marginTop: "25px",
            marginBottom: "25px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderLeft: "5px solid rgba(0, 0, 0, 0.06)",
            background: "rgba(0, 0, 0, 0.04)"
            }}>
        {props.children}
        </div>
    )
}

export default ContentBox;