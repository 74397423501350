import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Ruby from "..\\..\\..\\..\\src\\components\\lessons\\ruby";
import ContentBox from "..\\..\\..\\..\\src\\components\\lessons\\contentBox";
import * as React from 'react';
export default {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Ruby,
  ContentBox,
  React
};