import React from 'react';

import HeadsetIcon from '@material-ui/icons/Headset';

const play = (url) => {
    const audio = new Audio(url);
    audio.play();
    }

const PlayButton = (props) => {
    return (
        <span>
            <span 
                aria-label="play or pause"
                role="button"
                onClick={() => play(props.url)}
                onKeyPress={() => play(props.url)}
                style={{cursor: "pointer"}}
                tabIndex={props.url}
            >
                {props.hide ? null : <HeadsetIcon fontSize="small" />}
                <span>&nbsp;</span>
                {props.children}
            </span>
        </span>
    );
}

export default PlayButton;