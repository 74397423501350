import Box from '@material-ui/core/Box';
import ContentBox from "..\\..\\..\\..\\src\\components\\lessons\\contentBox";
import ExpandedContent from "..\\..\\..\\..\\src\\components\\lessons\\expandedContent";
import PlayButton from "..\\..\\..\\..\\src\\components\\lessons\\playbutton";
import Player from "..\\..\\..\\..\\src\\components\\lessons\\player";
import Ruby from "..\\..\\..\\..\\src\\components\\lessons\\ruby";
import * as React from 'react';
export default {
  Box,
  ContentBox,
  ExpandedContent,
  PlayButton,
  Player,
  Ruby,
  React
};