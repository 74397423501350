import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      margin: '0 auto'
    },
    panel: {
      // background: "none",
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.fontSize,
    },
    details: {
      fontSize: theme.typography.fontSize,
      width: "100%"
    },
  }));

const ExpandedContent = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <ExpansionPanel elevation={0} classes={{root: classes.panel}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                classes={{content: classes.summary}}
                >
                <Typography className={classes.heading}>Answer</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.details}>
                    {props.children}
                  </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

export default ExpandedContent;